var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "", "justify-center": "", "align-center": "" } },
    [
      _c(
        "v-flex",
        { staticClass: "comment-section", attrs: { xs12: "", "my-2": "" } },
        [
          _c("comments-container", {
            attrs: {
              comments: _vm.comments,
              totalComments: _vm.totalComments,
              activePage: _vm.activePage
            },
            on: {
              sortComments: _vm.onSortComments,
              loadComments: _vm.loadComments
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }