














import Vue from 'vue';
import CommentsContainer from '@/views/products/components/comments/comments-container.vue';
import { IComment, ICommentPage, ICommentStatus } from '@/scripts/store/modules/comments/types';

export default Vue.extend({
  name: 'community-comments',
  components: {
    CommentsContainer,
  },
  data() {
    return {
      sortBy: null as string | null,
      sortDir: null as string | null,
      activePage: 0,
    };
  },
  computed: {
    commentStatuses(): Array<IComment> {
      return this.$store.getters['comments/getCommentsStatuses'];
    },
    comments(): Array<IComment> {
      return this.$store.getters['comments/getComments'];
    },
    totalComments(): Array<IComment> {
      return this.$store.getters['comments/getTotalComments'];
    },
    loading(): boolean {
      return this.$store.state.loadingSpinner;
    },
  },
  mounted() {
    this.loadComments(1);
  },
  beforeDestroy() {
    this.$store.dispatch('comments/processCommentsMarkedAsReadLater');
  },
  methods: {
    loadComments(page: number) {
      this.$store.commit('setLoadingView', true);

      const config = {
        page: page > 0 ? page - 1 : 0,
        sort: this.sortBy || 'id',
        dir: this.sortDir || 'DESC',
      };

      Promise.all([
        this.$store.dispatch('comments/getCommentsByUser', config).then((res: ICommentPage) => {
          this.activePage = res.number + 1;
          return res;
        }),
        this.$store.dispatch('comments/getCommentsStatusesByUser').then((res: ICommentStatus[]) => {
          return res;
        }),
      ]).finally(() => {
        this.$store.commit('setLoadingView', false);
      });
    },
    onSortComments(sortBy: string, sortDir: string) {
      this.sortBy = sortBy;
      this.sortDir = sortDir;
      this.loadComments(1);
    },
  },
});
